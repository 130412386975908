export default {
  FETCH_INITIAL_DATA_SUCCESS: 'FETCH_INITIAL_DATA_SUCCESS',
  FETCH_INITIAL_DATA: 'FETCH_INITIAL_DATA',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
  SET_REDIRECT: 'SET_REDIRECT',
  RESET_REDIRECT: 'RESET_REDIRECT',

  // User actions
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILED: 'FETCH_USER_DATA_FAILED',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',

  SAVE_USER: 'SAVE_USER',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_FAILED: 'SAVE_USER_FAILED',

  // Orders actions
  FETCH_ORDERS: 'FETCH_ORDERS',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILED: 'FETCH_ORDERS_FAILED',

  RESET_ORDERS: 'RESET_ORDERS',

  FETCH_ORDER: 'FETCH_ORDER',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILED: 'FETCH_ORDER_FAILED',

  FETCH_ORDER_FOR_REPEAT: 'FETCH_ORDER_FOR_REPEAT',
  FETCH_ORDER_FOR_REPEAT_SUCCESS: 'FETCH_ORDER_FOR_REPEAT_SUCCESS',
  FETCH_ORDER_FOR_REPEAT_FAILED: 'FETCH_ORDER_FOR_REPEAT_FAILED',
  RESET_ORDER_FOR_REPEAT: 'RESET_ORDER_FOR_REPEAT',
  CHANGE_REPEAT_ORDER_POSITION: 'CHANGE_REPEAT_ORDER_POSITION',
  CHANGE_REPEAT_ORDER_POSITION_SUCCESS: 'CHANGE_REPEAT_ORDER_POSITION_SUCCESS',
  CHANGE_REPEAT_ORDER_POSITION_FAILED: 'CHANGE_REPEAT_ORDER_POSITION_FAILED',
  CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER: 'CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER',
  CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER_SUCCESS: 'CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER_SUCCESS',
  CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER_FAILED: 'CHANGE_REPEAT_ORDER_POSITIONS_FOR_ORDER_FAILED',
  ADD_REPEAT_ORDER_POSITION: 'ADD_REPEAT_ORDER_POSITION',
  ADD_REPEAT_ORDER_POSITION_SUCCESS: 'ADD_REPEAT_ORDER_POSITION_SUCCESS',
  ADD_REPEAT_ORDER_POSITION_FAILED: 'ADD_REPEAT_ORDER_POSITION_FAILED',
  SUBMIT_REPEAT_ORDER: 'SUBMIT_REPEAT_ORDER',
  SUBMIT_REPEAT_ORDER_SUCCESS: 'SUBMIT_REPEAT_ORDER_SUCCESS',
  SUBMIT_REPEAT_ORDER_FAILED: 'SUBMIT_REPEAT_ORDER_FAILED',
  FETCH_SUCCESS_ORDER: 'FETCH_SUCCESS_ORDER',
  FETCH_SUCCESS_ORDER_SUCCESS: 'FETCH_SUCCESS_ORDER_SUCCESS',
  FETCH_SUCCESS_ORDER_FAILED: 'FETCH_SUCCESS_ORDER_FAILED',
  RESET_SUCCESS_ORDER: 'RESET_SUCCESS_ORDER',

  // Product Actions
  SEARCH_PRODUCTS: 'SEARCH_PRODUCTS',
  SEARCH_PRODUCTS_SUCCESS: 'SEARCH_PRODUCTS_SUCCESS',
  SEARCH_PRODUCTS_FAILED: 'SEARCH_PRODUCTS_FAILED',

  // Addresses actions
  FETCH_DELIVERY_ADDRESSES: 'FETCH_DELIVERY_ADDRESSES',
  FETCH_DELIVERY_ADDRESSES_SUCCESS: 'FETCH_DELIVERY_ADDRESSES_SUCCESS',
  FETCH_DELIVERY_ADDRESSES_FAILED: 'FETCH_DELIVERY_ADDRESSES_FAILED',
  RESET_DELIVERY_ADDRESSES: 'RESET_DELIVERY_ADDRESSES',

  BUILD_DELIVERY_ADDRESS: 'BUILD_DELIVERY_ADDRESS',
  UPDATE_DELIVERY_ADDRESS: 'UPDATE_DELIVERY_ADDRESS',
  SAVE_DELIVERY_ADDRESSES: 'SAVE_DELIVERY_ADDRESSES',
  SAVE_DELIVERY_ADDRESSES_SUCCESS: 'SAVE_DELIVERY_ADDRESSES_SUCCESS',
  SAVE_DELIVERY_ADDRESSES_FAILED: 'SAVE_DELIVERY_ADDRESSES_FAILED',
  DELETE_DELIVERY_ADDRESS: 'DELETE_DELIVERY_ADDRESS',
  DELETE_DELIVERY_ADDRESS_SUCCESS: 'DELETE_DELIVERY_ADDRESS_SUCCESS',
  DELETE_DELIVERY_ADDRESS_FAILED: 'DELETE_DELIVERY_ADDRESS_FAILED',

  // Reviews actions
  FETCH_REVIEWS: 'FETCH_REVIEWS',
  FETCH_REVIEWS_SUCCESS: 'FETCH_REVIEWS_SUCCESS',
  FETCH_REVIEWS_FAILED: 'FETCH_REVIEWS_FAILED',

  RESET_REVIEWS: 'RESET_REVIEWS',

  DELETE_REVIEW: 'DELETE_REVIEW',
  DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS',
  DELETE_REVIEW_FAILED: 'DELETE_REVIEW_FAILED',
};
