import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const CloseIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || '#235aa2',
    height = props.height || 21,
    width = props.width || 21,

    svg = (props: IconProps) => (
      <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.883118L20 19.8831" stroke={color} stroke-linecap="square"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0.883118L1 19.8831L20 0.883118Z" stroke={color} strokeLinecap="square"/>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default CloseIcon;
