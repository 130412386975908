import React, {useState, useContext, useEffect} from 'react';
import StModal from "../../../app/Components/Modal";
import {Form, Input} from "antd";
import { Context as UserContext } from "../../contexts/User";
import 'antd/dist/antd.css';
import 'tailwindcss/dist/components.css';
import 'tailwindcss/dist/utilities.css';
import CustomButton from "../../../app/Components/Button";
import './style.scss';
import {secondsToFormat} from "../../helpers";
import Registration from "../Registration";

const Auth = (props) => {
  const
    [emailAuthVisible, setEmailAuthVisible] = useState(true),
    [phoneAuthVisible, setPhoneAuthVisible] = useState(false),
    [otpModalVisible, setOtpModalVisible] = useState(false),
    [email, setEmail] = useState(''),
    [phone, setPhone] = useState(''),
    [otp, setOtp] = useState(''),
    user:any = useContext(UserContext)

  const
    clearAllFields = () => {
      setEmail('');
      setPhone('');
      setOtp('');
    },

    closeAllModals = () => {
      setEmailAuthVisible(false);
      setPhoneAuthVisible(false);
      setOtpModalVisible(false);

      user.setLoginData([]);
      user.setOtpSent(false);

      clearAllFields();
    },

    handleShowMailAuth = (e) => {
      e.preventDefault();
      closeAllModals();
      setEmailAuthVisible(true);
    },

    handleShowPhoneAuth = (e) => {
      e.preventDefault();
      closeAllModals();
      setPhoneAuthVisible(true);
    },

    handleCloseApp = () => {
      closeAllModals();
      user.setLoginData([]);
      setTimeout(() => {
        window.app.modules.userMenu.closeAuthApp();
      }, 300);
    },

    handleEmailChange = (e) => {
      setEmail(e.target.value);
      if (user.error) {
        user.setError(null);
      }
    },

    handleOtpChange = (e) => {
      const val = e.target.value;

      if (val.length >= 6) {
        user.auth(val);
      }

      if (user.error) {
        user.setError(null);
      }

      setOtp(val);
    },

    handlePhoneChange = (e) => {
      setPhone(e.target.value);
      if (user.error) {
        user.setError(null);
      }
    },

    handleGetOtp = (type) => () => {
      user.getOtp(type === 'email' ? email : phone, type);
    },

    handleChangeLoginType = () => {
      closeAllModals();

      if (user.loginData[0] === 'email') {
        setEmailAuthVisible(true);
      } else {
        setPhoneAuthVisible(true);
      }
    },

    handleGetNewOtp = (e) => {
      e.preventDefault();
      setOtp('')
      user.setError(null);
      user.getNewOtp();
    };

  useEffect(() => {
    if (!user.otpSent || otpModalVisible) { return; }

    closeAllModals();

    setOtpModalVisible(true);

  }, [user.otpSent, otpModalVisible, closeAllModals, setOtpModalVisible]);

  const
    renderOtpLabel = () => {
      return user.loginData[0] === 'email' ?
        <p>Мы отправили код подтверждения на почту {user.loginData[1]?.toLowerCase()} <a href='#' onClick={handleChangeLoginType}>Изменить</a></p>
        : <p>Мы отправили код подтверждения  на номер {user.loginData[1]} <a href='#' onClick={handleChangeLoginType}>Изменить</a></p>
    };

  return (
    <>
      <StModal
        open={phoneAuthVisible}
        onCancel={handleCloseApp}
        width={620}
        className='st-modal_auth'
        footer={[
          <a key='1' href='#' className='text-blue' onClick={handleShowMailAuth}>Войти по почте</a>
        ]}
        title='Войти или зарегистрироваться'
      >
        <Form
          className='auth-form'
          layout="vertical"
        >
          <Form.Item label="Номер телефона" validateStatus={user.error ? 'error' : null} help={user.error ? user.error : null}>
            <Input name='auth_phone' onChange={handlePhoneChange} value={phone} placeholder='+7 000 000 00 00' />
          </Form.Item>
          <Form.Item>
            <CustomButton onClick={handleGetOtp('phone')}>Получить код</CustomButton>
          </Form.Item>
        </Form>
      </StModal>

      <StModal
        open={emailAuthVisible}
        onCancel={handleCloseApp}
        width={620}
        className='st-modal_auth'
        footer={[]}
        // footer={[<a key='1' href='#' className='text-blue' onClick={handleShowPhoneAuth}>Войти по номеру телефона</a>]}
        title='Вход по почте'
      >
        <Form
          className='auth-form'
          layout="vertical"
        >
          <Form.Item label="Почта" validateStatus={user.error ? 'error' : null} help={user.error ? user.error : null} >
            <Input name='email' placeholder='Введите электронную почту' value={email} onChange={handleEmailChange} />
          </Form.Item>
          <Form.Item>
            <CustomButton onClick={handleGetOtp('email')}>Получить код</CustomButton>
          </Form.Item>
        </Form>
      </StModal>

      <StModal
        open={otpModalVisible}
        onCancel={handleCloseApp}
        width={620}
        className='st-modal_auth'
        footer={
          []
          // user.loginData[0] === 'email' ?
          //   [<a key='1' href='#' className='text-blue' onClick={handleShowPhoneAuth}>Войти по номеру телефона</a>]
          //   : [<a key='1' href='#' className='text-blue' onClick={handleShowMailAuth}>Войти по почте</a>]
        }
        title='Введите код'
      >
        <Form
          className='auth-form'
          layout="vertical"
        >
          <Form.Item hasFeedback validateStatus={user.otpSuccess ? 'success' : user.error ? 'error' : null} help={user.otpSuccess ? 'Верно!' : user.error ? user.error : null}>
            {renderOtpLabel()}
            <Input
              style={{width: 160}}
              name='otp'
              maxLength={6}
              onChange={handleOtpChange}
              value={otp}
              placeholder='Введите код'
            />
          </Form.Item>
          {
            user.time ?
              <p>Получить новый код можно через {secondsToFormat(user.time)}</p>
              : <a href='#' onClick={handleGetNewOtp}>Получить новый</a>

          }
        </Form>
      </StModal>
      <Registration closeAllAuthModals={closeAllModals} />
    </>
  );
}

export default Auth;
