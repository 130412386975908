export default {
  HOME: '/dashboard',
  USER: '/dashboard/user',
  ORDERS: '/dashboard/orders',
  ORDER_REPEAT: (id = ':id') => `/dashboard/orders/${id}/repeat`,
  ORDER_SUCCESS: (id = ':id') => `/dashboard/orders/${id}/success`,
  ADDRESSES: '/dashboard/addresses',
  REVIEWS: '/dashboard/reviews',
  COMPARISONS: '/dashboard/comparisons',
  BONUSES: '/dashboard/bonuses',
};
