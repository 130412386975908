import React, {useState, useContext, useEffect} from 'react';
import StModal from "../../../app/Components/Modal";
import {Form, Input} from "antd";
import { Context as UserContext } from "../../contexts/User";
import CustomButton from "../../../app/Components/Button";
import './style.scss';
import UserForm from "../../../app/Components/UserForm";

const Registration = (props) => {
  const
    [modalVisible, setModalVisible] = useState(false),
    user:any = useContext(UserContext);

  useEffect(() => {
    if (!user.user || modalVisible ) { return; }
    props.closeAllAuthModals();
    setModalVisible(true);

  }, [user.user, modalVisible]);

  return (
    <>
      <StModal
        open={modalVisible}
        width={620}
        className='st-modal_registration'
        footer={null}
        title='Введите данные'
      >
        <UserForm
          hideEmail
          dictionaries={user.dictionaries}
          user={user.user}
          updateUser={user.update}
          saveUser={user.save}
        />
      </StModal>
    </>
  );
}
export default Registration;
