import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const BagIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || '#000',
    height = props.height || 51,
    width = props.width || 45,

    svg = (props: IconProps) => (
      <svg width={width} height={height} viewBox="0 0 45 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.25" y="13.25" width="42.5" height="36.5" rx="6.75" fill="url(#paint0_linear_1_1815)" stroke={color} stroke-width="2.5"/>
        <path d="M20 1.25H25C29.8325 1.25 33.75 5.16751 33.75 10V13.75H11.25V10C11.25 5.16751 15.1675 1.25 20 1.25Z" fill="url(#paint1_linear_1_1815)" stroke={color} stroke-width="2.5"/>
        <path d="M18.25 25.25H26.75V30C26.75 31.5188 25.5188 32.75 24 32.75H21C19.4812 32.75 18.25 31.5188 18.25 30V25.25Z" fill="url(#paint2_linear_1_1815)" stroke={color} stroke-width="2.5"/>
        <line x1="1" y1="25.75" x2="43" y2="25.75" stroke={color} stroke-width="2.5"/>
        <defs>
          <linearGradient id="paint0_linear_1_1815" x1="22.5" y1="12" x2="22.5" y2="51" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1_1815" x1="22.5" y1="0" x2="22.5" y2="15" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1_1815" x1="22.5" y1="24" x2="22.5" y2="34" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default BagIcon;
