import React, {useState} from 'react';
import Auth from "./components/Auth";
import { contextProviderHOC as UserProvider } from "./contexts/User";
import compose from "compose-function";

const AuthApp = () => {
  const [authAppEnabled, setAuthAppEnabled] = useState(false);

  window.enableAuthApp = () => setAuthAppEnabled(true);

  window.disableAuthApp = () => setAuthAppEnabled(false);

  return authAppEnabled ? (
    <Auth />
  ) : null;
}

export default compose(
  UserProvider
)(AuthApp);
