const ACCOUNT_PARAMS = ['opf', 'buyer_type', 'name', 'phone', 'inn', 'kpp', 'id', 'city_id', 'acc_number', 'acc_number2', 'company_name', 'bank', 'bik', 'ogrn'];

export const userUpdateParams = (userState) => {
  const
    {currentUser} = userState,
    {account} = currentUser,
    params = {};
  ACCOUNT_PARAMS.forEach(attr => {
    if (account[attr]) {
      params[attr] = account[attr]
    }
  });

  return {
    account: {
      ...params
    },
    email: currentUser.email,
    subscribe: currentUser.subscribe
  }
}
