// tmpId, create random string id, for new objects in collections, not fetched from API
export const tmpId = () => Math.random().toString(36).substr(2, 5);

// updateCollection, change attributes of objects in array suitable on request
// example:
// a = [{key: 1, foo: 2}, {key: 4, bar: 5}]; updateCollection(a, (item) => item.key == 4, {bar: 10, foo: 5}) =>
// [{key: 1, foo: 2}, {key: 4, bar: 10, foo: 5}]
// args:
//    collection: array of objects
//    condition: f(item): item - iterated obj, must return Boolean
//    changes: Object
export const updateCollection = (collection, condition, changes) => {
  return collection.map((item) => {
    if (condition(item)) {
      return Object.assign({}, item, changes);
    } else {
      return item;
    }
  });
};

// toggle value in array
// example:
// a = []; toggleInArray(a, '1') => [1]
// toggleInArray(a, '1') => []
export const toggleInArray = (array, id) => {
  const index = array.indexOf(id);
  if (index < 0) {
    array.push(id);
  } else {
    array.splice(index, 1);
  }
  return array;
};

// Check that objects are equal
// optionsEquals({a: 1, b: {a: 1}}, {a: 1, b: {a: 2}}) => false
// optionsEquals({a: 1, b: {a: 1}}, {a: 1, b: {a: 1}}) => true
export const optionsEquals = (currOpts, prevOpts) => {
  if (Array.isArray(currOpts)) {
    for (let i = 0; i < currOpts.length; i += 1) {
      if (
        currOpts[i].value !== prevOpts[i].value ||
        currOpts[i].label !== prevOpts[i].label
      ) {
        return false;
      }
    }
    return currOpts.length === prevOpts.length;
  }
  const keys = Object.keys(currOpts);
  for (let i = 0; i < keys.length; i += 1) {
    if (currOpts[keys[i]] !== prevOpts[keys[i]]) {
      return false;
    }
  }
  return Object.keys(currOpts).length === Object.keys(prevOpts).length;
};

// copyToClipBoard, save passed text to sys clipboard
export const copyToClipboard = (text) => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

// buildLocalUrl, create url string from route
// buildLocalUrl('orders/:id/edit', {id: 15}) => 'orders/15/edit'
export const buildLocalUrl = (url, params) => {
  if (url.indexOf(':id') >= 0) {
    if (typeof params.id === 'undefined') {
      console.error(url, 'must have param id');
    } else {
      url = url.replace(':id', params.id);
      delete params.id;
    }
  }
  return url;
};

export const isBlank = (str) => {
  return (!str || /^\s*$/.test(str));
};

export const nestStringProperties = (obj) => {
  if (!obj) { return {}; }

  const isPlainObject = obj => !!obj && obj.constructor === {}.constructor,

    getNestedObject = obj => Object.entries(obj).reduce((result, [prop, val]) => {
      prop.split('.').reduce((nestedResult, prop, propIndex, propArray) => {
        const lastProp = propIndex === propArray.length - 1;
        if (lastProp) {
          nestedResult[prop] = isPlainObject(val) ? getNestedObject(val) : val;
        } else {
          nestedResult[prop] = nestedResult[prop] || {};
        }
        return nestedResult[prop];
      }, result);
      return result;
    }, {});

  return getNestedObject(obj);
};

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const priceFormat = (price) => {
  const pr = Number(price);
  return Math.round(pr) !== pr ? pr.toFixed(2) : String(pr);
}
