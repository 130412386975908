import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const SearchIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || "#002033",
    svg = (props: IconProps) => (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.63633 10.7675C8.42669 11.6901 6.91167 12.1186 5.39797 11.9663C3.88408 11.814 2.48465 11.0921 1.48304 9.94676C0.481431 8.80146 -0.0474979 7.31835 0.00335423 5.79774C0.0542063 4.27714 0.681039 2.83267 1.75693 1.75684C2.83281 0.681005 4.27735 0.0542036 5.79803 0.00335406C7.31872 -0.0474955 8.8019 0.481407 9.94726 1.48296C11.0926 2.48452 11.8146 3.88389 11.9669 5.3977C12.1192 6.91151 11.6906 8.42665 10.7677 9.6363L16 14.8683L14.8683 16L9.63633 10.7675ZM10.3964 5.99805C10.3964 7.16466 9.93297 8.28348 9.10802 9.10839C8.28307 9.93331 7.16419 10.3967 5.99753 10.3967C4.83087 10.3967 3.71199 9.93331 2.88704 9.10839C2.06209 8.28348 1.59863 7.16466 1.59863 5.99805C1.59863 4.83145 2.06209 3.71262 2.88704 2.88771C3.71199 2.0628 4.83087 1.59937 5.99753 1.59937C7.16419 1.59937 8.28307 2.0628 9.10802 2.88771C9.93297 3.71262 10.3964 4.83145 10.3964 5.99805Z" fill={color} fillOpacity="0.3"/>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default SearchIcon;
