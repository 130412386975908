import CustomInput from "../../Components/Input";
import PhoneInput from "../../Components/PhoneInput";
import CustomSelect from "../../Components/Select";
import {nestStringProperties} from '../../tools/helpers';
import CustomButton from "../../Components/Button";
import CustomCheckbox from "../../Components/Checkbox";
import React from "react";
import OpfSelector from "../../Components/OpfSelector";
import './style.scss';
import UserFormField from './Field';
import _ from 'lodash';

const UserForm = (props) => {
  const
    handleChange = (attr) => (e) => {
      const val = e.target ? e.target.value : e;

      props.updateUser(nestStringProperties({[attr]: val}));
    },

    handleCheckboxChange = (attr) => (e) => {
      props.updateUser(nestStringProperties({[attr]: e.target.checked}));
    },

    handleBuyerTypeChange = (val) => {
      props.updateUser(nestStringProperties({['account.buyer_type']: val}));
    },

    handleSave = () => {
      props.saveUser();
    },

    {
      account, email, hasChanges, errors, subscribe
    } = props.user,
    isLegal = account?.buyer_type === 'legal',
    companyFields = [
      {label: 'Наименование', attribute: 'company_name'},
      {label: 'ИНН', attribute: 'inn'},
      {label: 'КПП', attribute: 'kpp'},
      {label: 'ОГРН(ОГРНИП)', attribute: 'ogrn'},
      {label: 'Юр. Адрес', attribute: 'legal_address'},
      {label: 'Р/с', attribute: 'acc_number'},
      {label: 'К/с', attribute: 'acc_number2'},
      {label: 'БИК', attribute: 'bik'},
      {label: 'Банк', attribute: 'bank'},
    ]

  return (
    <div className='dashboard-form dashboard-form_user'>
      <div className='dashboard-form__row w-full'>
        <OpfSelector buyer_type={account.buyer_type} onChange={handleBuyerTypeChange}/>
      </div>
      <div className='dashboard-form__row w-full'>
        <div className='dashboard-form__label'>{isLegal ? 'Контактное лицо' : 'ФИО'}</div>
        <CustomInput
          value={account?.name}
          onChange={handleChange('account.name')}
          placeholder='Введите ФИО'
        />
        {errors && errors['authenticable.name'] ? <div className='dashboard-form__error'>{errors['authenticable.name']}</div> : null}
      </div>
      <div className='dashboard-form__row w-2/4'>
        <div className='dashboard-form__label'>Телефон</div>
        <PhoneInput
          value={account?.phone}
          onChange={handleChange('account.phone')}
          placeholder='Введите телефон'
        />
        {errors && errors['authenticable.phone'] ? <div className='dashboard-form__error'>{errors['authenticable.phone']}</div> : null}
      </div>
      {
        !props.hideEmail ?
          <div className='dashboard-form__row w-2/4'>
            <div className='dashboard-form__label'>Электронная почта</div>
            <CustomInput
              onChange={handleChange('email')}
              placeholder='Введите электронную почту'
              value={email}
            />
            {errors && errors['email'] ? <div className='dashboard-form__error'>{errors['email']}</div> : null}
          </div>
          : null
      }
      <div className='dashboard-form__row w-2/4'>
        <div className='dashboard-form__label'>Город:</div>
        <CustomSelect
          className='w-full'
          value={account?.city_id}
          onChange={handleChange('account.city_id')}
          placeholder='Выберите город'
          options={props.dictionaries.cities.map(city => ({value: city.id, label: city.name}))}
        />
        {errors && errors['authenticable.city_id'] ? <div className='dashboard-form__error'>{errors['authenticable.city_id']}</div> : null}
      </div>
      {
        account?.buyer_type === 'legal' ?
          <>
            <div className='dashboard-form__row w-2/4'>
              <div className='dashboard-form__label'>Организационно-правовая форма</div>
              <CustomSelect
                className='w-full'
                value={account?.opf || 'ooo'}
                onChange={handleChange('account.opf')}
                placeholder='Выберите организационно-правовую форму'
                options={[
                  {value: 'ooo', label: 'ООО'},
                  {value: 'ip', label: 'ИП'}
                ]}
              />
            </div>
            {
              companyFields.map((field) => (
                <UserFormField
                  key={field.attribute}
                  label={field.label}
                  errors={errors}
                  attribute={`authenticable.${field.attribute}`}
                >
                  <CustomInput
                    onChange={handleChange(`account.${field.attribute}`)}
                    placeholder={field.label}
                    value={_.get(account, field.attribute)}
                  />
                </UserFormField>
              ))
            }
          </>
          : null
        // <>
        //   <CustomButton className='w-full clean justify-start' onClick={this.handleBuyerTypeChange} >Добавить данные организации</CustomButton>
        // </>
      }
      <div className='dashboard-form__row w-full mt15'>
        <div className='dashboard-form__label'>Подписка</div>
        <div className='dashboard-form__label dashboard-form__label_checkbox'>

          <CustomCheckbox
            onChange={handleCheckboxChange('subscribe')}
            checked={subscribe}
          >
            Хочу получать информацию о скидках, выгодных предложениях на электронную почту
          </CustomCheckbox>
        </div>
      </div>
      <div className='dashboard-form__row w-2/4 mt-7'>
        <div className='mobile-submit__container'>
          <CustomButton disabled={!hasChanges} onClick={handleSave} className='w-4/5 blue mobile-submit__btn save-btn'>Сохранить</CustomButton>
        </div>
      </div>
    </div>
 );
}

export default UserForm;
