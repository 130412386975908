import ChevronDownIcon from "./ChevronDown";
import StopIcon from "./Stop";
import DeliveryBoxIcon from "./DeliveryBox";
import PlaceMarkerIcon from "./PlaceMarker";
import CloseIcon from "./Close";
import SearchIcon from "./Search";
import LoadingIcon from "./Loading";
import AccountIcon from "./Account";
import BagIcon from "./Bag";

export const ChevronDown = ChevronDownIcon;
export const Stop = StopIcon;
export const DeliveryBox = DeliveryBoxIcon;
export const PlaceMarker = PlaceMarkerIcon;
export const Close = CloseIcon;
export const Search = SearchIcon;
export const Loading = LoadingIcon;
export const Account = AccountIcon;
export const Bag = BagIcon;
