import React from 'react';
import CustomInput from '../../Input';

const UserFormField = ({errors, attribute, children, className = 'w-2/4', label}) => {
  return (
    <div className={`dashboard-form__row ${className}`}>
      <div className='dashboard-form__label'>{label}</div>
      { children }
      {errors && errors[attribute] ? <div className='dashboard-form__error'>{errors[attribute].join(', ')}</div> : null}
    </div>
  );

}

export default UserFormField;
