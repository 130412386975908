import React from 'react';
import {Select} from 'antd';
import ChevronDown from '../Icons/ChevronDown';
import './style.scss';

type Props = React.ComponentProps<typeof Select>

const CustomSelect = (props: Props): JSX.Element => {
  return (
    <Select
      {...props}
      className={'st-select ' + props.className || ''}
      size='large'
      suffixIcon={<ChevronDown />}
    >
      {props.children}
    </Select>
  );
};

export default CustomSelect;
