import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const LoadingIcon = ({color = '#fff', height = 20, width = 20, ...rest}: IconProps): JSX.Element => {
  const
    svg = (props: IconProps) => (
      <svg width={width} height={height} viewBox="0 0 200 200" color={color} fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>

        <g strokeWidth={Number(width) / 1.5}>
          <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
          <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
          <path stroke="currentColor" strokeLinecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
        </g>

        <animateTransform from="0 0 0" to="360 0 0" attributeName="transform" type="rotate" repeatCount="indefinite"
                          dur="800ms" />
      </svg>
    );

  return (
    <Icon
      {...rest}
      component={svg}
    />
  );
};

export default LoadingIcon;
