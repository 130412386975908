import React from 'react';
import _ from 'lodash';
import {Button} from 'antd';
import './style.scss';

type StandardProps = React.ComponentProps<typeof Button>

interface Props extends StandardProps {
  success?: boolean
  primary?: boolean
  danger?: boolean
}

const
  TYPES = ['success', 'primary', 'danger'],
  CustomButton = (props: Props): JSX.Element => {
    const
      className = _.compact(['st-btn', ...(TYPES.map(item => props[item] ? item : null)), ...(props.className || '').split(' ')]),
      filteredProps = _.omit(props, TYPES);

    return (
      <Button
        {...filteredProps}
        type='text'
        className={_.uniq(className).join(' ')}
      />
    );
  };
export default CustomButton;
