import React, {useState, useCallback, useEffect} from "react";
import {ajax} from '../../app/tools/request';
import constants from "../constats";
import dashboardConstant from '../../app/constants';
import {userUpdateParams} from '../../app/tools/params_builders/user';

import {parseTime} from "../helpers";
import _ from 'lodash';

const Context:any = React.createContext({});
const getCsrf = () => document.querySelector('meta[name="csrf-token"]').content;
const loginAjax = async (path, params, type = 'GET') => {
  const
    baseParams = {
      authenticity_token: getCsrf()
    };
  return await fetch(path, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrf(),
      'X-Requested-With': 'XMLHttpRequest'
    },
    method: type,
    body: JSON.stringify({...baseParams, ...params})
  });
}

const AFTER_SIGN_IN_REDIRECT_PATH = '/dashboard/user';

const ContextState = (props) => {
  const
    [user, setUser] = useState(null),
    [dictionaries, setDictionaries] = useState(null),
    [time, setTime] = useState(null),
    [otpSent, setOtpSent] = useState(false),
    [loading, setLoading] = useState(false),
    [loginData, setLoginData] = useState([]),
    [token, setToken] = useState(null),
    [error, setError] = useState(''),
    [otpSuccess, setOtpSuccess] = useState(null),

    getOtp = async (login, type) => {
      setLoading(true);
      const
        params = {type};

      params[type === 'email' ? 'email' : 'phone'] = login;

      const request = await loginAjax(constants.OTP_LOGIN_PATH, {
        email: login,
        authenticity_token: getCsrf(),
        type
      }, 'POST');

      if (request.ok) {
        setOtpSent(true);
        const response = await request.json();

        if (response.otp_time_left) {
          setTime(parseTime(response.otp_time_left));
        }
        if (response.html) {
          const
            parser = new DOMParser(),
            page = parser.parseFromString(response.html, 'text/html');
          setToken(page.querySelector('input[name="authenticity_token"]').value);
        }
      } else {
        if (request.status === 422) {
          location = AFTER_SIGN_IN_REDIRECT_PATH;

        }
        setError('Не верный логин');
      }
      setLoading(false);
      setLoginData([type, login]);
    },

    getNewOtp = async () => {
      setLoading(true);

      const request = await loginAjax(constants.OTP_REFRESH_PATH, {
        email: loginData[1],
        type: loginData[0]
      }, 'POST');

      if (request.ok) {
        setOtpSent(true);
      }

      const response = await request.json();

      if (response.otp_time_left) {
        setTime(parseTime(response.otp_time_left));
      }

      if (response.token) {
        setToken(response.token);
      }

      setLoading(false);
    },

    auth = async (code) => {
      const form = new FormData();

      form.append('authenticity_token', token);
      form.append('user[email]', loginData[1]);
      form.append('user[password]', '1');
      form.append('user[otp_attempt]', code);

      const
        request = await fetch(constants.OTP_SIGN_PATH, {
          headers: {
            'X-CSRF-Token': getCsrf(),
            'X-Requested-With': 'XMLHttpRequest'
          },
          method: 'post',
          body: form
        });

      if (request.ok) {
        setOtpSuccess(true);
        const
          userResponse = await ajax({url: dashboardConstant.API.ROUTES.USERS_PATH}),
          dictsResponse = await ajax({url: dashboardConstant.API.ROUTES.DICTIONARIES_PATH});

        if (userResponse && dictsResponse) {
          const userJson = userResponse.data;

          if (userResponse.data) {
            userJson.errors = {...userJson.errors, ...userJson.account.errors}
          }

          if (userJson.account['registration_complete?']) {
            location = AFTER_SIGN_IN_REDIRECT_PATH;
          } else {
            setUser(userJson);
            setDictionaries(dictsResponse.data)
          }
        }
      } else {
        setError('Неверный код.')
      }
    };

  const save = async (callback) => {
    const
      params = userUpdateParams({currentUser: user}),
      userResponse = await ajax({
        url: dashboardConstant.API.ROUTES.USER_PATH,
        method: 'PUT',
        params
      });

    if (userResponse.data) {
      userResponse.data.errors = {...userResponse.data.errors, ...userResponse.data.account.errors}
    }
    setUser(userResponse.data);
    console.log(user.account);
    if (userResponse.data.account['registration_complete?']) {
      window.location = AFTER_SIGN_IN_REDIRECT_PATH;
    }
  };

  const update = (changes) => {
    setUser({..._.merge(user, {...changes, hasChanges: true, errors: null})});
  };

  useEffect(() => {
    if (!time) { return; }
    if (time <= 0) {
      setTime(null);
      return;
    }
    setTimeout(() => setTime(time - 1), 1000);
  }, [time, setTime])

  return (
    <Context.Provider
      value={{
        auth,
        getOtp,
        user,
        loading,
        otpSent,
        setOtpSent,
        loginData,
        setLoginData,
        time,
        error,
        setError,
        otpSuccess,
        dictionaries,
        update,
        save,
        getNewOtp,
        props
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const contextProviderHOC = (Component) => {
  return (props) => (
    <ContextState>
      <Component {...props} />
    </ContextState>
  );
};

export {Context, contextProviderHOC};
