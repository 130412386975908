import React from 'react';
import ReactDOM from 'react-dom';
import Auth from 'auth';
const appContainerId = 'auth-app';

const renderPack = Component => {
  ReactDOM.render(<Component /> , document.querySelector(`#${appContainerId}`));
};

document.addEventListener('DOMContentLoaded', renderPack(Auth));
// window.execAuthApp = () => renderPack(Auth);

if (module.hot) {
  module.hot.accept('auth', () => {
    const NextApp = require('auth').default;
    document.querySelector(`#${appContainerId}`).classList.remove('active')
    renderPack(NextApp);
  });
}
