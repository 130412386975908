import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const AccountIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || '#000',
    height = props.height || 51,
    width = props.width || 47,

    svg = (props: IconProps) => (
      <svg width={width} height={height} viewBox="0 0 47 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5127 13.2415C11.5127 6.64611 16.9088 1.25 23.5042 1.25C30.0996 1.25 35.4957 6.64611 35.4957 13.2415C35.4957 19.8369 30.0996 25.233 23.5042 25.233C16.9088 25.233 11.5127 19.8369 11.5127 13.2415Z" fill="url(#paint0_linear_1_1812)" stroke={color} strokeWidth="2.5"/>
        <path d="M45.0314 37.4392L45.0243 37.4478L44.9698 37.5432C44.5427 38.2907 44.0567 38.9944 43.5031 39.7142L43.4952 39.7243L43.4876 39.7347C42.7337 40.7577 41.8604 41.6888 40.9502 42.5991C40.9501 42.5991 40.9501 42.5991 40.9501 42.5991L45.0314 37.4392ZM45.0314 37.4392C44.7305 36.71 44.3315 36.0131 43.8708 35.3475L43.8631 35.3365L43.8632 35.3364C41.4006 31.6962 37.6153 29.3001 33.3628 28.7078C33.0095 28.6769 32.7221 28.7645 32.5805 28.8707L32.5729 28.8764L32.5729 28.8764C29.9238 30.8317 26.7711 31.8384 23.504 31.8384C20.2368 31.8384 17.0842 30.8317 14.4351 28.8764L14.4274 28.8707L14.4274 28.8707C14.2165 28.7125 13.9369 28.6534 13.7457 28.6916L13.7085 28.6991L13.6708 28.7043C9.40013 29.2915 5.55504 31.6943 3.15185 35.3259L3.14463 35.3368L3.13718 35.3475C2.67269 36.0185 2.25044 36.8071 1.93213 37.5485C1.93528 37.5582 1.93947 37.5695 1.94492 37.582C2.3649 38.3105 2.89578 39.056 3.3985 39.7262L3.40491 39.7348L3.40487 39.7348C4.17106 40.7747 4.98863 41.7008 5.9151 42.5727L5.9289 42.5857L5.94231 42.5991C6.69275 43.3496 7.55894 44.0559 8.4865 44.8095C12.8313 48.0476 18.0535 49.75 23.4462 49.75C28.8464 49.75 34.0757 48.0428 38.4241 44.7959L45.0314 37.4392Z" fill="url(#paint1_linear_1_1812)" stroke={color} strokeWidth="2.5"/>
        <defs>
          <linearGradient id="paint0_linear_1_1812" x1="23.5042" y1="0" x2="23.5042" y2="26.483" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1_1812" x1="23.5004" y1="27.4302" x2="23.5004" y2="51" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default AccountIcon;
