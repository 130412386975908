import React from 'react';
import {Modal} from "antd";
import './style.scss';

const StModal = (props) => {
  return (
    <Modal {...{...props, className: `st-modal ${props.className}`}} />
  );
}

export default StModal;
