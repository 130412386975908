import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const ChevronDownIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || '#4f4f4f',
    svg = (props: IconProps) => (
      <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <mask id='path-1-inside-1' fill='white'>
          <path fillRule='evenodd' clipRule='evenodd' d='M9.89895 0.949763L4.94922 5.89953L-0.000513196 0.949763'/>
        </mask>
        <path d='M4.94922 5.89953L3.88856 6.96019L4.94922 8.02085L6.00988 6.96019L4.94922 5.89953ZM8.83829 -0.110901L3.88856 4.83886L6.00988 6.96019L10.9596 2.01043L8.83829 -0.110901ZM6.00988 4.83886L1.06014 -0.110901L-1.06117 2.01043L3.88856 6.96019L6.00988 4.83886Z' fill={color} mask='url(#path-1-inside-1)'/>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default ChevronDownIcon;
