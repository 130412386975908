import React from "react";
import {Account, Bag} from "../Icons";
import './style.scss';

const OpfSelector = (props) => {
  const
    isPhysical = props.buyer_type === 'physical',
    isLegal = props.buyer_type === 'legal',
    inactiveColor = '#7B8693',
    onChange = (e) => {
      props.onChange && props.onChange(e.target.value);
    };

  return (
    <div className='opf-selector'>
      <label className={`opf-selector__item ${isPhysical ? 'active' : ''}`}>
        <input type='radio' checked={isPhysical} value='physical' onChange={onChange} />
        <span className='opf-selector__item-content'>
          <span className='opf-selector__item-icon'><Account color={isPhysical ? null : inactiveColor} /></span>
          <span className='opf-selector__item-title'>Частное лицо</span>
        </span>
      </label>
      <label className={`opf-selector__item ${isLegal ? 'active' : ''}`}>
        <input type='radio' checked={isLegal} value='legal' onChange={onChange} />
        <span className='opf-selector__item-content'>
          <span className='opf-selector__item-icon'><Bag color={isLegal ? null : inactiveColor} /></span>
          <span className='opf-selector__item-title'>Организация</span>
        </span>
      </label>
    </div>
  )
}

export default OpfSelector;
