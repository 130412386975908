import React from 'react';
import {Input} from 'antd';
import './style.scss';

type Props = React.ComponentProps<typeof Input>

class CustomInput extends React.Component<Props, null> {
  render(): JSX.Element {
    return (
      <Input
        {...this.props}
        className={'st-input ' + this.props.className || ''}
        size='large'
      />
    );
  }
}

export default CustomInput;
