export const secondsToFormat = (sec) => {
  let totalSec = sec;
  totalSec = totalSec % 3600;
  let
    minutes = Math.floor(totalSec / 60),
    seconds = totalSec % 60;

  minutes = String(minutes).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");

  return (minutes + ":" + seconds);
}

export const parseTime = (time) => {
  const
    timeParts = time.split(':');
  return (+timeParts[0]) * 60 + (+timeParts[1]);
}
