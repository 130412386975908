export default {
  ENTRYPOINT: '/api/v1',
  ROUTES: {
    USERS_PATH: '/user/me',
    DICTIONARIES_PATH: '/common/dictionaries',
    USER_PATH: '/user',
    ORDERS_PATH: '/orders',
    ORDER_PATH: (id = ':id') => `/orders/${id}`,
    ORDER_REPEAT_PATH: (id = ':id') => `/orders/${id}/repeat`,
    ORDER_REPEAT_ADD_PRODUCT_PATH: (id = ':id') => `/orders/${id}/repeat/add-product`,
    ORDER_REPEAT_CHANGE_POSITION_PATH: (id = ':id') => `/orders/${id}/repeat/change-position`,
    ORDER_REPEAT_ADD_FOR_ORDER_PATH: (id = ':id') => `/orders/${id}/repeat/add-for-order`,
    PRODUCTS_PATH: '/products',
    DELIVERY_ADDRESSES_PATH: '/delivery_addresses',
    DELIVERY_ADDRESS_PATH: (id = ':id') => `/delivery_addresses/${id}`,
    REVIEWS_PATH: '/reviews',
    REVIEW_PATH: (id = ':id') => `/reviews/${id}`,
    ORDER_BILL_PATH: (id = ':id') => `/cart/bill/${id}`,
    ORDER_RECEIPT_PATH: (id = ':id') => `/cart/receipt/${id}`,
  },
};
