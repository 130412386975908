import React from 'react';
import Icon from '@ant-design/icons';
import {IconProps} from '../../../typings/icons';

const StopIcon = (props: IconProps): JSX.Element => {
  const
    color = props.color || '#ED0000',
    svg = (props: IconProps) => (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="9.5" stroke={color} strokeWidth="3"/>
        <line x1="18.0235" y1="4.09658" x2="3.02348" y2="18.0966" stroke="#ED0000" strokeWidth="3"/>
      </svg>
    );

  return (
    <Icon
      {...props}
      component={svg}
    />
  );
};

export default StopIcon;
